import WOW from 'wow.js'

import Component from '../core/Component'

export default class Animation extends Component {
    prepare() {
        const wow = new WOW(
          {
            offset: 150
        });

        wow.init();
    }
}
