// general
import Antispam from '../../components/Antispam'
// import CookieBubble from '../../components/CookieBubble'
// project
import Animation from './Animation'
import Carousel from './Carousel'
import ChartBar from './ChartBar'
import ChartBarPercentage from './ChartBarPercentage'
import ChartPillars from './ChartPillars'
import ChartSimple from './ChartSimple'
import CustomScrollbar from './CustomScrollbar'
import Filter from './Filter'
import NavCloser from './NavCloser'
import NavOpener from './NavOpener'
import ScrollAnchors from './ScrollAnchors'
import ScrollTo from './ScrollTo'
import Select from './Select'
import SidebarNav from './SidebarNav'
import Sticky from './Sticky'

const Components = {
    Antispam,
    // CookieBubble,
    Animation,
    Carousel,
    ChartBar,
    ChartBarPercentage,
    ChartPillars,
    ChartSimple,
    CustomScrollbar,
    Filter,
    NavOpener,
    NavCloser,
    ScrollAnchors,
    ScrollTo,
    Select,
    SidebarNav,
    Sticky
}

export default Components
