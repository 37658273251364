import enquire from 'enquire.js'

import Component from '../core/Component'

export default class NavOpener extends Component {
    constructor(element) {
        super(element)
    }

    prepare() {
        this.element.addEventListener('click', this.handleClick)

        enquire.register('screen and (min-width: 767px)', {
            match: () => {
                this.element.addEventListener('mouseenter', this.handleClick);
            },
            unmatch: () => {
                this.element.removeEventListener('mouseenter', this.handleClick);
            }
        });
    }

    destroy() {
        this.element.removeEventListener('click', this.handleClick)
        this.element.removeEventListener('mouseenter', this.handleClick)

        enquire.unregister('screen and (min-width: 767px)');
    }

    handleClick = () => {
        document.documentElement.classList.toggle('nav-is-open')
        document.querySelector(".Navigation").classList.toggle('is-active')
        document.querySelector(".Navigation-overlay").classList.toggle('is-active')

        const navLabel = document.querySelector('.NavButton .NavButton-label')

        if (navLabel) {
            if (document.documentElement.classList.contains('nav-is-open')) {
                if (document.documentElement.lang === "cs") {
                    navLabel.textContent = "Zavřít";
                } else {
                    navLabel.textContent = "Close";
                }
            } else {
                navLabel.textContent = "Menu";
            }
        }
    }
}
